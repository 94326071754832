import React from "react";
// import { Link } from "gatsby";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";
import Container from "./container";

import * as styles from "./project.module.css";
import { browseMoreNav } from "./project-preview-list.module.css";

function Project(props) {
  const { _rawBody, title, categories, mainImage } = props;
  return (
    <article className={styles.root}>
      {props.mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1800)
              .height(Math.floor((3 / 4) * 1800))
              .fit("crop")
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {categories && categories.length > 0 && (
              <ul className={styles.categories}>
                {categories.map((category) => (
                  <li key={category._id}>{category.title}</li>
                ))}
              </ul>
            )}
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
          </div>
          {/* <aside className={styles.metaContent}> */}
          {/* {members && members.length > 0 && (
              <RoleList items={members} title="Project members" />
            )} */}
          {/* {categories && categories.length > 0 && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map((category) => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )} */}
          {/* {relatedProjects && relatedProjects.length > 0 && (
              <div className={styles.relatedProjects}>
                <h3 className={styles.relatedProjectsHeadline}>
                  Related projects
                </h3>
                <ul>
                  {relatedProjects.map((project) => (
                    <li key={`related_${project._id}`}>
                      {project.slug ? (
                        <Link to={`/work/${project.slug.current}`}>
                          {project.title}
                        </Link>
                      ) : (
                        <span>{project.title}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )} */}
          {/* </aside> */}
          <div className={browseMoreNav}>
            <a href="/work">view all work</a>
          </div>
        </div>
      </Container>
    </article>
  );
}

export default Project;
