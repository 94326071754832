// extracted by mini-css-extract-plugin
export var categories = "project-module--categories--9d5fb";
export var categoriesHeadline = "project-module--categoriesHeadline--8c2e6 typography-module--base--16985";
export var grid = "project-module--grid--45373";
export var mainContent = "project-module--mainContent--5b4d5";
export var mainImage = "project-module--mainImage--bc6be";
export var metaContent = "project-module--metaContent--3bed8";
export var publishedAt = "project-module--publishedAt--a68d0 typography-module--small--2877f";
export var relatedProjects = "project-module--relatedProjects--097e4";
export var relatedProjectsHeadline = "project-module--relatedProjectsHeadline--9a974 typography-module--base--16985";
export var root = "project-module--root--fbfdc";
export var title = "project-module--title--0805d typography-module--responsiveTitle1--d2dd2";